import { Component, Prop } from 'vue-property-decorator';
import Browser from '@/support/browser';
import Globals from '@/globals';
import PlayerApi from '@/support/playerApi';
import StudioPageBase from '@/support/studioPageBase';
import Util from '@/support/utility';
import { EventBus } from '@/support/eventBus';
import { IWizardManager, IPermissionDto } from '@/interfaces';

@Component
export default class PermissionsComponent extends StudioPageBase {
    options = {
        showAddNewPermssion: Browser.getBoolParam('showAddNewPermssion', false) || Browser.getBoolParam('show', false),
    };

    @Prop({ default: null })
    wizard: IWizardManager;

    @Prop({ default: <any>{} })
    parent: any;

    loading = true;
    loaded = false;

    refresh = {
        lastRefreshCheck: new Date(),
        playIntervalMs: 20 * 1000, // playing check interval time
    };

    timers = {
        refreshIntervalId: 0,
    };

    inherited: IPermissionDto[] = [];
    permissions: IPermissionDto[] = [];

    deletePermission = <IPermissionDto>{};

    $refs = {
        addEveryoneAtMicrosoft: null,
        addNewUser: null,
        removePermission: null
    };

    get disabledAddEveryoneAtMicrosoft() {

        let found = this.permissions.find(item => item.title == Globals.EveryoneAtMicrosoft);
        return found != null;
    }

    created() {
        Debug.setDebugModule('Permissions', this);

        super.created('permissions', true);

        EventBus.Instance.$on(EventBus.PermissionsChanged, () => {
            this.getPermissionList();
        });
    }

    mounted() {
        super.mounted();

        Debug.log("permissions mounted");

        this.getPermissionList();

        if (this.options.showAddNewPermssion) {
            this.addNewUser();
        }
    }

    async getPermissionList() {
        try {
            let response = await PlayerApi.permissionsList(this.studio.studio);

            this.inherited.splice(0, this.inherited.length);
            this.permissions.splice(0, this.permissions.length);

            response.permissions.forEach(permission => {
                let permObj = <IPermissionDto>permission;
                permObj.busy = false;

                if (permObj.isAllStudio)
                    this.inherited.push(permObj);
                else
                    this.permissions.push(permObj);
            });

            Debug.log('getPermissionList', this.permissions.length, this.inherited.length, 'Permissions');
            return true;
        } catch (err) {
            let message = err.message || 'ERROR';
            Util.showError('Failed to get Permission list ' + message);
            return null;
        } finally {
            this.loaded = true;
            this.loading = false;
        }
    }

    done() {
        this.wizard.back();
    }

    addNewUser() {
        this.$refs.addNewUser.show();
    }

    addEveryoneAtMicrosoft() {
        this.$refs.addEveryoneAtMicrosoft.show();
    }

    removePermission(permission: IPermissionDto, event: any = null) {
        this.$refs.removePermission.show(permission);
    }

}
